/** Make sure to respect the same object shape for each experiment; variation names can variate.
 * This information gets used also by the inactive-experiments-detector script. **/
const HOMEPAGE_HEADER_SIGN_UP_WITH_FREE_COPY = {
  ID: '24319120794',
  VARIATIONS: {
    CONTROL: '24313510510',
    VARIATION: '24347270537',
  },
}

const PRICING_4_DOT_0 = {
  ID: '25503330074',
  VARIATIONS: {
    CONTROL: '25414570543',
    VARIATION: '25439440719',
  },
}

const TEXT_WRAP_BALANCED = {
  ID: '26722270257',
  VARIATIONS: {
    CONTROL: '26755470296',
    VARIATION: '26591741455',
  },
}

const TEMPLATE_ARTICLE_PAGE_HERO = {
  ID: '28774880492',
  VARIATIONS: {
    CONTROL: '28801790820',
    NEW_HERO_HORIZONTAL: '28914290005',
    NEW_HERO_VERTICAL: '28932930310',
  },
}

const INTEGRATIONS_INTENT_SURVEY = {
  ID: '29439130038',
  VARIATIONS: {
    CONTROL: '29412780022',
    VARIATION: '29439140022',
  },
}

const SIGNUP_CAPTCHA = {
  ID: '9300001277693',
  VARIATIONS: {
    ON: '722369',
    OFF: '722368',
  },
}

const TYPEFORM_FOR_GROWTH = {
  ID: '9300001279421',
  VARIATIONS: {
    OFF: '723157',
    ON: '723158',
  },
}

const BLOG_HOMEPAGE_CLOSING_MODULE = {
  ID: '9300001435067',
  VARIATIONS: {
    CONTROL: '795121',
    VARIATION: '795122',
  },
}

const BLOG_CATEGORY_CLOSING_MODULE = {
  ID: '9300001435364',
  VARIATIONS: {
    CONTROL: '795286',
    VARIATION: '795287',
  },
}

const CELLO_REFERRAL_INVITE_LP_HERO = {
  ID: '9300001509995',
  VARIATIONS: {
    OFF: '825742',
    ON: '825743',
  },
}

const SIGNUP_WITH_LINKEDIN = {
  ID: '9300001538658',
  VARIATIONS: {
    CONTROL: '837732',
    VARIATION: '837733',
  },
}

const SIGNUP_WIDGET = {
  ID: '9300001634045',
  VARIATIONS: {
    CONTROL: '880452',
    VARIATION: '884010',
  },
}

const NOW_YOU_KNOW_EXPLORE = {
  ID: '9300001680311',
  VARIATIONS: {
    CONTROL: '914108',
    VARIATION: '914109',
  },
}

const INTEGRATIONS_REBRAND_V2 = {
  ID: '9300001680170',
  VARIATIONS: {
    OFF: '914030',
    ON: '914031',
  },
}

export {
  HOMEPAGE_HEADER_SIGN_UP_WITH_FREE_COPY,
  PRICING_4_DOT_0,
  TEXT_WRAP_BALANCED,
  TEMPLATE_ARTICLE_PAGE_HERO,
  INTEGRATIONS_INTENT_SURVEY,
  SIGNUP_CAPTCHA,
  TYPEFORM_FOR_GROWTH,
  BLOG_HOMEPAGE_CLOSING_MODULE,
  BLOG_CATEGORY_CLOSING_MODULE,
  CELLO_REFERRAL_INVITE_LP_HERO,
  SIGNUP_WITH_LINKEDIN,
  SIGNUP_WIDGET,
  NOW_YOU_KNOW_EXPLORE,
  INTEGRATIONS_REBRAND_V2,
}
